<template>
  <BreadCrumb PageTitle="Mes business" />
  <BusinessContent />
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import BusinessContent from "../../components/Financial/BusinessContent.vue";


export default defineComponent({
  name: "BusinessPage",
  components: {
    BreadCrumb,
    BusinessContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>