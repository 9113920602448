<template>
  <div class="clearfix"  v-if="!loader">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="loader" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <!-- <button
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
          type="button"         
          @click="exportToPDF"
          style="background-color: #EB9355;"
        >
          Pdf
          <i class="flaticon-printer"></i>
        </button> -->
        <button
          class="default-outline-btn bg-transparent position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
          @click="showScreen('excel')"
          data-bs-toggle="modal"
          data-bs-target="#filterLeadModal"
        >
        <!-- text-black bg-transparent -->
          Excel
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button>
        
      </div>
      <div class="d-sm-flex align-items-center" >           
          <div>
              <div
                class="select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0"
              >
                <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                <input
                  v-model="today"
                  type="date"
                  class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                  id="lead-calendar"
                  :placeholder="leadPlaceholder"
                  @change="searchTransaction1"
                />
              </div>
          </div>   
        
          <button style="width:max-content"
              type="button"
              class="default-btn d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25"          
              @click="redirect('/multi-transferts')"
            >
            Envoi multiple
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
              type="button"
              class="d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25"
              data-bs-toggle="modal"
              data-bs-target="#sendMoneyModal"  
              style="background-color: #EB9355;" 
            >
            Envoi simple
          </button>
          
          <button
              class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0"
              type="button"   
              data-bs-toggle="modal"
              data-bs-target="#filterLeadModal"   
              @click="showScreen('search')"   
          >        
            <i class="flaticon-dots"></i>
          </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table id="" class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                CODE TRANSACTION  
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                BENEFICIAIRE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                OPERATEUR
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TELEPHONE
              </th>
               <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                MONTANT
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                STATUT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>

            <tr  v-for="(transaction) in transactions" :key="transaction.payoutId">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                    />
                  </div>
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                   
                    {{ transaction.payoutId}}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.clientId}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.operator.mnoName}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.contact}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.amount.toLocaleString()}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ formatDateFr(new Date(transaction.transactionDate))}}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="transaction.paymentStatus==='SUCCEEDED'">SUCCESS</span>
                <span class="badge text-outline-danger" v-if="transaction.paymentStatus==='FAILED'">ECHEC</span>
                <span class="badge  text-outline-info" v-if="transaction.paymentStatus==='PROCESSING'">EN COURS</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        @click="loadTransaction(`${transaction.payoutId}`)"
                        href="javascript:void(0);"
                        class="dropdown-item d-flex align-items-center position-relative transition border-0 fw-medium text-black pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal"                        
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Voir</a
                      >
                    </li>
                    <li>
                      <a target="_blank"
                        class="dropdown-item d-flex align-items-center"
                        href="https://wa.me/message/SV5FPBLDUDCBJ1?text=urlencodedtext"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Réclamation</a
                      >
                    </li> 
                    <li>
                      <!-- <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      > -->
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
           
          </tbody>
        </table>
        <div id="transactionsTable">
        <h2 class="text-center" id="title">Liste des transactions </h2>
        <table id="" class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                CODE TRANSACTION
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                BENEFICIAIRE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                OPERATEUR
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TELEPHONE
              </th>
               <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                MONTANT
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                STATUT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>

            <tr  v-for="(transaction) in transactions" :key="transaction.payoutId">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                 
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                   
                    {{ transaction.payoutId}}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.clientId}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.operator.mnoName}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.contact}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transaction.amount.toLocaleString()}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ formatDateFr(new Date(transaction.transactionDate))}}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span  v-if="transaction.paymentStatus==='SUCCEEDED'">SUCCESS</span>
                <span  v-if="transaction.paymentStatus==='FAILED'">ECHEC</span>
                <span  v-if="transaction.paymentStatus==='PROCESSING'">EN COURS</span>
              </td>
              
            </tr>
           
          </tbody>
        </table>
      </div>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav v-if="gettran==true"   class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getTransactions(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getTransactions(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getTransactions(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>

        <nav  v-if="search==true"  class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0"  class="page-item">
              <a class="page-link" @click="searchTransaction(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="searchTransaction(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"   class="page-item">
              <a class="page-link"  @click="searchTransaction(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>

        <nav  v-if="search1==true"  class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0"  class="page-item">
              <a class="page-link" @click="searchTransaction1($event,page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="searchTransaction1($event,numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="searchTransaction1($event,page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
 
  <div class="modal fade" id="sendMoneyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <form  @submit.prevent="sendMoney">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Envoi d'argent</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div  style="margin-bottom: 5%;">
                        <h6 class="text-black fw-bold mb-0 text-center">{{ 'SOLDE'}}</h6>
                        <h6 class="text-black fw-bold mb-0 text-center" style="color: red;">{{ payoutBalance?.toLocaleString()}} XOF</h6>
                      </div>    
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <!-- <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div> -->
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                            <div class="row">                          
                              <div class="col-md-4">
                                      <label class="d-block text-black fw-semibold mb-10">
                                      Téléphone 
                                    </label>
                                    <div >
                                            <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                            <input
                                              v-model="formValues.contact"
                                              type="text"
                                              class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                              id="contact"
                                              placeholder="ex: 0500457801"
                                              @blur="handlePhone($event.target.value,$event.target)"
                                              @focus="initOperator($event.target.value,$event.target)"
                                              @keyup="handlePhone($event.target.value,$event.target)" 
                                              @change="validate('contact')"
                                              :class="{ 'is-invalid': errorsBis['contact'] }"
                                            />
                                            <span class="invalid-feedback">{{ errorsBis['contact']}}</span> 
                                    </div>                               
                                </div>
                                <div class="col-md-4">
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Opérateurs
                                    </label>
                                    <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChange" required>
                                        <option value="">Selectionnez...</option>                 
                                        <option v-for="operator in operators" :value="operator.mnoName" :key="operator.code"> {{ operator.mnoName }}</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Montant
                                    </label>              
                                    <div >                                     
                                            <input @keyup="validate_phone_number($event.target.value,$event.target)" 
                                              v-model="formValues.amount"
                                              type="number"
                                              class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                              id="amount"
                                              placeholder="2"
                                              @blur="validate('amount')"                                          
                                              :class="{ 'is-invalid': errorsBis['amount'] }"
                                            />
                                            <span class="invalid-feedback">{{ errorsBis['amount']}}</span> 
                                    </div>
                            </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4" >
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Business
                                    </label>              
                                    <select  name="business" id="business" class="form-select fs-md-15 text-black shadow-none" @change="handleBusinessChange" >
                                        <option value="">Selectionnez...</option>                 
                                        <option v-for="businessItem in business" :value="businessItem.code" :key="businessItem.code"> {{ businessItem.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-4" v-if="current_permissions('SUPERADMIN')">
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Clients
                                    </label>              
                                    <select  name="organization" id="organization" class="form-select fs-md-15 text-black shadow-none" @change="handleOrganizationChange" required>
                                        <option value="">Selectionnez...</option>                 
                                        <option v-for="organization in organizations" :value="organization.code" :key="organization.id"> {{ organization.name }}</option>
                                    </select>
                                </div>
                                
                            </div>
                            
                            <div class="row">
                              

                            </div>
                      </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;">
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
          </form>
        </div>
    </div>
 </div> 

  <div class="modal fade" id="filterLeadModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 v-if="screenSelected=='search'" class="modal-title fs-5">Recherche avancé</h1>
                <h1 v-if="screenSelected=='excel'" class="modal-title fs-5">Exportation des transactions au format excel</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">code: {{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
                        
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">                          
                              <div class="col-md-12" v-if="current_permissions('USER')">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Pays
                                  </label>              
                                  <select  name="organization" id="organization" class="form-select fs-md-15 text-black shadow-none" @change="handleCountryChange" required>
                                      <option value="">Selectionnez...</option>                 
                                      <option  value="SN" >Sénégal</option>
                                      <option  value="CI" > Côte d'Ivoire</option>
                                  </select>
                              </div>
                          </div>
                         <div class="row">
                            <div class="col-md-6" v-if="current_permissions('SUPERADMIN')">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Clients
                                </label>              
                                <select  name="organization" id="organization" class="form-select fs-md-15 text-black shadow-none" @change="handleOrganizationChange" required>
                                    <option value="">Selectionnez...</option>                 
                                    <option v-for="organization in organizations" :value="organization.code" :key="organization.id"> {{ organization.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-6" v-if="current_permissions('SUPERADMIN')">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Pays
                                </label>              
                                <select  name="organization" id="organization" class="form-select fs-md-15 text-black shadow-none" @change="handleCountryChange" required>
                                    <option value="">Selectionnez...</option>                 
                                    <option  value="SN" >Sénégal</option>
                                    <option  value="CI" > Côte d'Ivoire</option>
                                </select>
                            </div>
                          </div>
                          <div class="row">
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Opérateurs
                                  </label>
                                  <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChange" required>
                                      <option value="">Selectionnez...</option>                 
                                      <option v-for="operator in operators" :value="operator.id" :key="operator.code"> {{ operator.mnoName }}</option>
                                  </select>
                              </div>
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Statuts
                                  </label>                                  
                                  <select  name="status" id="status" class="form-select fs-md-15 text-black shadow-none" @change="handleStatusChange" required>
                                    <option value="">Selectionnez...</option>                 
                                    <option  value="SUCCEEDED" > SUCCESS</option>
                                    <option  value="PROCESSING" > EN COURS</option>
                                    <option  value="FAILED" > ECHEC</option>                                      
                                  </select>                                  
                              </div>
                        </div>
                          <div class="row">
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Date debut 
                              </label>
                              <div >
                                      <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                      <input
                                        v-model="beginDate"
                                        type="text"
                                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                        id="lead-calendar"
                                        :placeholder="leadPlaceholder"
                                      />
                              </div>
                          </div>
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Date fin
                              </label>
                              <div >
                                <!-- class="select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0" -->
                                    
                                    <input
                                    v-model="endDate"
                                      type="text"
                                      class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                      id="lead-calendar"
                                      :placeholder="leadPlaceholder"
                                    />
                              </div>
                          </div>
                        </div>

                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button v-if="screenSelected=='search'"  type="button" class="btn" style="background-color: #EB9355;"  data-bs-dismiss="modal" @click="searchTransaction(1)">
                  <i class="flaticon-search-interface-symbol" style="color: #FFF;"></i>
                </button>
                <button v-if="screenSelected=='excel'"  :disabled="!btnLoad" type="button" class="btn" style="background-color: #EB9355;" @click="searchTransactionForExport()">           
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <span style="color: #FFF;">Exporter</span> 
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  >
            <div class="modal-header">
                <h1 class="modal-title fs-5">Details transaction</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">
                                <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    code transaction
                                  </label>              
                                  <div >                                     
                                          <input
                                            v-model="transaction.payoutId"
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                            readonly                                      
                                        
                                          />
                                        
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Telephone
                                  </label>              
                                  <div >                                     
                                          <input
                                            v-model="transaction.contact"
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                            readonly                                      
                                        
                                          />
                                        
                                  </div>
                              </div>

                          </div>
                        <div class="row">
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Montant 
                              </label>
                              <div >
                                      <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                      <input
                                        v-model="amount"
                                        type="text"
                                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                        
                                        readonly
                                      />
                                      
                              </div>
                          </div>
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Date
                              </label>
                              <div >
                                                                   
                                    <input
                                      v-model="transaction.transactionDate"
                                      type="text"
                                      class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                      readonly                                     
                                    />
                                    
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Opérateur 
                              </label>
                              <div >
                                      <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                      <input
                                        v-model="operator"
                                        type="text"
                                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                        
                                        readonly
                                      />
                                      
                              </div>
                          </div>
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Statut
                              </label>
                              <div >
                                                                   
                                    <input
                                      v-model="paymentStatus"
                                      
                                      type="text"
                                      class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                      readonly                                     
                                    />
                                    
                              </div>
                          </div>
                      </div>                       



                    </div>
            </div>
            
            </form>
        </div>
      
    </div>
</div>

<div id="snackbar"></div>
</template>

<script setup  lang="ts">
import {ref, onMounted,onBeforeMount,reactive,inject } from "vue";
import * as yup from 'yup';
import axios from 'axios';
import flatpickr from "flatpickr";
import html2pdf from "html2pdf.js";
import LeadscontentPdf from "./LeadsContentPdf.vue";
import router from '../../../router/index'
import conf from '../../../../env.json'
import {current_permissions} from '../../../utils/permission';
import { read, utils } from 'xlsx';
import {redirect} from '../../../utils/services';
import "intl-tel-input/build/css/intlTelInput.css";
import 'vue-tel-input/vue-tel-input.css';
import intlTelInput from 'intl-tel-input';
import { showToast,validate_phone_number,checkSessionValidate } from "@/utils/function";
import { Business } from "@/utils/Model";
// import {SERVER} from "../../../utils/const"
// import {export_json_to_excel} "../../../utils/Export2Excel";

const backApi=inject('back');

declare interface Transaction {
    id: number,
    payoutId: string,
    clientId: string,
    operator: string,
    contact: string,
    amount: string,    
    transactionDate: string,
    paymentStatus: string
  }

  declare interface Organization {
  id : string,
  code: string,
  name: string,
  description: string,    
  email: string,
  created_at: string
  }

  declare interface Operator {
  id : string,
  code: string,
  mnoName: string,
  status: string,    
  countryCode: string  
  }

const business =ref(new Array<Business>());
let  iti ;
const leadPlaceholder = "Select date";
const errors = ref('');  
const errorId = ref('');  
const success = ref('');
const errorsBis = {'contact':'','amount':''};
const formValues = reactive({    
    contact: "",
    amount:""
  });
let codeOrganization: string|null = localStorage.getItem('organization_code');
let account_size  =  parseFloat(localStorage.getItem('account_size')+'');
let personnal_account_status  =  localStorage.getItem('personnal_account_status');

const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({     
     contact: yup.string().required('Le numéro de téléphone est requis'),//.matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),     
     amount: yup.number().required('Le montant est requis'),
  });
let data = [];
let beginDate = ref('');
let endDate = ref('');
let today = ref('');
let role: string|null = localStorage.getItem('role');
const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedStatus = ref('');
const selectedCountry = ref('');
const selectedBusiness = ref(null);
const loader = ref(false);
const page = ref(1);
const total = ref(0);
//const pageServ = ref('');
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const gettran = ref(false);
const search = ref(false);
const search1 = ref(false);
const amount = ref(0);
const operator = ref('');
const paymentStatus = ref(''); 
let importArray = [];
const screenSelected = ref(''); 
const btnLoad = ref(true);
const payoutBalance =ref(0);
let balance:number = 0;
let country_code = 'ci';    
let phone_length=0;
const transactions =ref(new Array<Transaction>());
const organizations =ref(new Array<Organization>());
const operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();
const transaction =ref({} as Transaction);

  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

function formatDateEn(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function formatDateEnBis(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function handlePhone(myvalue,tag) {
     country_code =iti.getSelectedCountryData().iso2;
      phone_length=0;
      switch (country_code) {
        case 'ci':
          phone_length=process.env.VUE_APP_CI_PHONE_LENTGH;
          break;        
        case 'sn':
          phone_length=process.env.VUE_APP_SN_PHONE_LENTGH;
          break;
        default:
          alert("Sélectionnez un pays");
      }
      validate_phone_number(myvalue,tag,phone_length)

}

function handleBusinessChange(event) {
      selectedBusiness.value = event.target.value;
}

function handleOrganizationChange(event) {
      selectedOrganization.value = event.target.value;
}

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
}

function handleStatusChange(event) {
  selectedStatus.value = event.target.value;
}

function handleCountryChange(event) {
   selectedCountry.value = event.target.value;
  // operators
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
}

function initOperator(myValue,tag){        
  country_code =iti.getSelectedCountryData().iso2;
    
        var j = 0;
        let operatorArr = new Array<Operator>();
        while(j<operatorsTemp.length){    
            if(operatorsTemp[j].countryCode == country_code.toUpperCase()){         
                operatorArr.push(operatorsTemp[j]);
            }      
            j++;
        }
        // alert(operatorArr[0].mnoName);
        // alert(waveradio.value);
        operators.value = operatorArr;
    }

function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
}
 

onMounted(() => {
  checkSessionValidate(1);

  if(account_size>0 && personnal_account_status=="ACTIVE"){
    getTransfertBalancePerso()
  }else{
    getBalancePayout();
  }
  

  getTransactions();
  getOrganizations();
  getOperators();
  getBusiness();
  //loader.value=true; 
  setTimeout(function () {
    //loader.value=true; 
        flatpickr("#lead-calendar", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: "today",
        enableTime: true,
      });
        },1000);

 

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });

  const inputc = document.querySelector("#contact");
  
  iti = intlTelInput(inputc, {
    // any initialisation options go here
    onlyCountries: ["ci","sn"],
    // i18n: ci,
    initialCountry: "auto",
  // utilsScript: "/intl-tel-input/js/utils.js?1722010922246",
  geoIpLookup: function(callback) {
    fetch("https://ipapi.co/json")
      .then(function(res) { return res.json(); })
      .then(function(data) { callback(data.country_code); })
      .catch(function() { callback("ci"); });
  }
  });

});
const token =  localStorage.getItem('login_token');
const usercode =  localStorage.getItem('code');

const getBusiness = (arg=1) =>{
    // +arg
    let param = '';
    param = (role=='SUPERADMIN')?'':`?organization=${codeOrganization}`
    axios.get(backApi+'/api/v1/secure/business/'+param,{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        business.value = response.data;           
        

    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const getTransfertBalancePerso = () =>{

axios.post(backApi+'/api/v1/secure/accounts/balance/payout',{
        user: usercode,         
        },{  
        headers: {
            
              Authorization: `Bearer ${token}`
        } 
})
.then((response) => { 
  // const balanceTemp =  formatNumber(parseInt(response.data.balance));   
  // transfert.value = balanceTemp;
  payoutBalance.value = response.data.balance;  //.data.users  
  balance = response.data.balance;               
  
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            //router.push('/')                    
          }                       
      }
});

}
const loadTransaction = (code)=>{
  
  axios.get(`${backApi}/api/v1/secure/transactions/${code}/payout`,{//SERVER+ //https://test.zivwallet.com/api/countries//dateD=${today}&
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transaction.value = response.data;  //.data.users   
        data  = response.data.transactions;  
        operator.value=  response.data.operator.mnoName;
        amount.value= response.data.amount.toLocaleString();
       
         if(response.data.paymentStatus==='SUCCEEDED'){
          paymentStatus.value = "SUCCESS"
         }else if(response.data.paymentStatus==='PROCESSING'){
          paymentStatus.value = "EN COURS"
         }else{
          paymentStatus.value = "ECHEC"
         }   
         
        loader.value=true;
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });
}
const getTransactions = (arg=1) =>{
    loader.value=false;
    gettran.value = true;
    search.value = false;
    search1.value = false;
    
    const todayB =  formatDateEn(new Date()); //'2024/01/01'
    const todayE =  formatDateEn(new Date(),1);   
    //conf.proxy
    axios.get(`${backApi}/api/v1/secure/transactions?page=${arg}&dateD=${todayB}&dateF=${todayE}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries//dateD=${today}&
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data.transactions;  //.data.users   
        data  = response.data.transactions;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        
        for (let pas = 0; pas < data.length; pas++) {
          data[pas].operator_name = data[pas].operator.mnoName;
        }    
         //console.log(pagination.value);
        loader.value=true;
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });

}
const getOrganizations = () =>{

    axios.get(backApi+'/api/v1/secure/organizations/',{  
      headers: {
           
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {
        // users.value = response.data;
        organizations.value = response.data;  //.data.users          
        console.log(response.data);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
                //router.push('/');
              }   
              
          }
   });
  //  loader.value=true;
}
const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}

const exportExcel = () =>{
  
  import("../../../utils/Export2Excel").then((excel)=>{
    // const data = transactions.value;
    const Header =["CODE TRANSACTION","OPERATEUR","TELEPHONE","MONTANT","DATE","STATUT"];
    const Fields = ["payoutId","operator_name","contact","amount","transactionDate","paymentStatus"];//mnoName
    // console.log(data);
    const dataForExcel= formatJSon(Fields,data);
    // console.log(dataForExcel);
    excel.export_json_to_excel({     
      header:Header,
      data:dataForExcel,
      sheetName:"Feuil1",
      filename:"transactions",      
      autoWidth: true,
      bookType:"xlsx",
  });
  });
}

function formatJSon(FilterData,JsonData){
  return JsonData.map((v)=>FilterData.map(j=>{
      return v[j];
    }));
}

const exportToPDF = () =>{ 
  
       document.getElementById("transactionsTable").style.display='block';
       const opt ={margin: [1, 0, 0, 0],filename: "transactions.pdf",jsPDF:{ unit: 'in', format: 'letter', orientation: 'portrait' }}
      //landscape//portrait
      
      html2pdf().set(opt).from(document.getElementById("transactionsTable")).toPdf().get('pdf').then(function (pdf) {
      
      window.open(pdf.output('bloburl'), '_blank');
      document.getElementById("transactionsTable").style.display='none';
     });

      
}
//[top, left, bottom, right]
const searchTransaction = (arg=1)=>{
  loader.value=false;
  gettran.value = false;
  search.value = true;
  search1.value = false;
  
  if(beginDate.value===''){
    beginDate.value =  formatDateEn(new Date()); 
  }
   
  if(endDate.value===''){
    endDate.value =  formatDateEn(new Date(),1); 
  }
    const todayE =  formatDateEn(new Date(endDate.value),1);   
  axios.get(`${backApi}/api/v1/secure/transactions?page=${arg}&dateD=${beginDate.value}&dateF=${todayE}&organization=${selectedOrganization.value}&operator=${selectedOperator.value}&paymentStatus=${selectedStatus.value}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data.transactions;  //.data.users   
        data  = response.data.transactions;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        for (let pas = 0; pas < data.length; pas++) {
          data[pas].operator_name = data[pas].operator.mnoName;
        }    
        //  console.log(data);
        loader.value=true; 
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });
}
const searchTransaction1 = (event,arg=1)=>{ 
  // alert(typeof event ) 
  // alert(event.target.value)
  // alert(arg)  
   if(typeof event.target.value!=='undefined'){
    today.value = event.target.value 
   }  
  
    // alert(today.value)  
    loader.value=false;
    gettran.value = false;
    search.value = false;
    search1.value = true;

    const todayE =  formatDateEn(new Date(today.value),1);   
  axios.get(`${backApi}/api/v1/secure/transactions?page=${arg}&dateD=${today.value}&dateF=${todayE}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data.transactions;  //.data.users   
        data  = response.data.transactions;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        for (let pas = 0; pas < data.length; pas++) {
          data[pas].operator_name = data[pas].operator.mnoName;
        }    
        //  console.log(data);
        loader.value=true; 
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });
}

const sendMoney =()=>{
if(parseFloat(formValues.amount)>0 && parseFloat(formValues.amount)<=balance && selectedOperator.value!=='' && formValues.contact.length==phone_length){
  try {  
    schema.validateSync(formValues);
    axios.post(`${backApi}/api/v1/secure/transactions/init`,{
      operator:selectedOperator.value,
      amount:formValues.amount,
      mobile:formValues.contact,
      operationType:parseInt(process.env.VUE_APP_PAYOUT_OPTTYPE),
      countryCode:country_code,
      business:selectedBusiness.value,
      successUrl: "https://billing.api/success",
      errorUrl: "https://billing.api/error"
          }, {  
        headers: {            
              Authorization: `Bearer ${token}`,
            'Content-Type':'application/json',
        }       
        }).then((response) => {  
          if(response.status === 201){      
            //router.go();
            
            showToast("Transfert réussi","green");
            formValues.amount='';
            formValues.contact='';
            selectedOperator.value='';
            selectedCountry.value=''; 
            //console.log(response);
          }      
          router.go();
      }).catch((error) => {
        if( error.response){
                //console.log(error.response.data); // => the response payload 
                if(error.response.status === 401){
                  router.push('/')
                  
                }else{
                  errorId.value=error.response.data.errorId;
                  errors.value=error.response.data.message;
                } 
                
            }
    });
  } catch (error) {
          console.error(error);        
          errors.value="une erreur inattendue est apparue"
        
  }
}else{
    if(parseFloat(formValues.amount)==0 || parseFloat(formValues.amount)<0){
        showToast("Veuillez saisir un montant correct","red");
    }
    if(parseFloat(formValues.amount)>balance){
        showToast("Solde insuffisant pour cette opération","red");
    }
    if(selectedOperator.value==''){
        showToast("Veuillez sélectionner un opérateur","red");
    }
    if(formValues.contact.length!=phone_length){
      showToast("Veuillez saisir un numéro de téléphone correct","red");
    }
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value='';
        success.value='';
}

const searchTransactionForExport = ()=>{  
   btnLoad.value=false;
  
  if(beginDate.value===''){
    beginDate.value =  formatDateEn(new Date()); 
  }
   
  if(endDate.value===''){
    endDate.value =  formatDateEn(new Date(),1); 
  }
    const todayE =  formatDateEn(new Date(endDate.value),1);   
  axios.get(`${backApi}/api/v1/secure/transactions/list/export?dateD=${beginDate.value}&dateF=${todayE}&organization=${selectedOrganization.value}&operator=${selectedOperator.value}&paymentStatus=${selectedStatus.value}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        // transactions.value = response.data.transactions;  //.data.users 
        if(response.status === 200){   
          data  = response.data;  
          // console.log(data)
          
          for (let pas = 0; pas < data.length; pas++) {
            data[pas].operator_name = data[pas].operator.mnoName;
          }  
          exportExcel()  
          //  console.log(data);
          btnLoad.value=true; 
        } 
    }).catch((error) => {
      btnLoad.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;   
              }  
              
          }
  });
}

const showScreen = (screen)=>{ 
  screenSelected.value=screen
}

const getBalancePayout = () =>{

axios.post(backApi+`/api/v1/secure/accounts/balance/payout`,{//collect//collectBalance
          code: codeOrganization,         
        },{  
        headers: {
            
              Authorization: `Bearer ${token}`
        } 
})
.then((response) => {    
    payoutBalance.value = response.data.balance;  //.data.users  
    balance = response.data.balance;       
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}

</script>
<style scoped>
#title{
 text-decoration: underline;
 margin-bottom: 2%;
}
#transactionsTable{
 display: none; 
 /* visibility: hidden;*/
}
</style>