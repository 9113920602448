<template>
  <BreadCrumbDetail PageTitle="Les comptes de " />
  <AccountContentByCompany />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumbDetail from "../../components/Common/BreadCrumbDetail.vue";
import AccountContentByCompany from "../../components/Financial/AccountContentByCompany.vue";


export default defineComponent({
  name: "UsersByCompanyPage",
  components: {
    BreadCrumbDetail,
    AccountContentByCompany,
  },
});
</script>