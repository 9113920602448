<template>
  <BreadCrumb PageTitle="Configuration du compte" />
  <FormValidation />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import FormValidation from "../../components/Settings/GeneralSetting.vue";

export default defineComponent({
  name: "GeneralSettingPage",
  components: {
    BreadCrumb,
    FormValidation,
  },
});
</script>