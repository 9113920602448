<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <button
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#companyModal"
          style="background-color: #EB9355;"
          @click="initField"
        >
          {{role=='ADMIN'?"Créer un sous-compte":"Créer un compte"}}
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
       
      </div>
      <div class="d-sm-flex align-items-center">
        
        <div
          class="select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0"
        >
         
        </div>
       
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
               ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOM
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
               TYPE
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
             SOLDE
              </th>  -->
              <th scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
             RATTACHÉ À
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
             STATUT
              </th> 
              
             
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>

            <tr  v-for="(account) in accounts" :key="account.code">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                    />
                  </div>
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                   
                    {{ account.code}}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ account.name}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ account.type}}
              </td> 
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ account.user?.name}}
              </td>            
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ user.balance}}
              </td> -->
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="account.status==='ACTIVE'">ACTIVE</span>
                <span class="badge text-outline-danger" v-if="account.status==='INACTIVE'">INACTIVE</span>                
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                 <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <!-- <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal"
                        @click="showUser(user.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Modifier
                        </a
                      >
                    </li> -->
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="changeStatus(account.status,account.code)"

                        ><i
                          class="flaticon-logout lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="account.status==='ACTIVE'">Désactiver</span>
                        <span v-if="account.status==='INACTIVE'">Activer</span>
                        </a
                      >
                    </li>
                    
                  </ul>
                </div>
              </td>
            </tr>
           
          </tbody>
        </table>
      </div>
     
    </div>
  </div>

  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="save">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Creation d'un compte</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">                        
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                          
                          <div class="row">
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Nom du compte
                                  </label>
                                  <div >
                                          
                                          <input
                                            v-model="formValues.name"
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                            
                                            placeholder="ex: Ouattara Patrick"
                                            @blur="validate('name')"
                                            @keyup="validate('name')"
                                            :class="{ 'is-invalid': errorsBis['name'] }"
                                          />
                                          <span class="invalid-feedback">{{ errorsBis['name']}}</span> 
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Type de compte
                                  </label>
                                  <div >                                                                      
                                    <select  name="role" id="role" class="form-select fs-md-15 text-black shadow-none" @change="handleTypeChange" required>
                                        <option value="PAYOUT" >PAYOUT</option>                 
                                        <option v-if="role=='SUPERADMIN'" value="AVAILABLE" >AVAILABLE</option> 
                                        <option v-if="role=='SUPERADMIN'" value="COLLECT">COLLECT</option> 
                                    </select>  
                                  </div>
                              </div>
                        </div>
                        <div class="row" v-if="role=='ADMIN'">
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                  Utilisateurs
                                </label>              
                                <select  name="mode" id="mode" class="form-select fs-md-15 text-black shadow-none" @change="handleUserChange" >
                                    <option value="">Selectionnez...</option>                 
                                    <option v-for="user in users" :value="user.code" :key="user.code"> {{ user.name }}</option>
                                </select>
                                
                            </div>
                        </div>
                      
                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
<div id="snackbar"></div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import flatpickr from "flatpickr";
import { useRoute, useRouter } from 'vue-router'
import conf from '../../../env.json'
import router from '../../router/index'
import {showToast,checkSessionValidate} from "../../utils/function";
import {Account,User} from "@/utils/Model";
import * as yup from 'yup';
const backApi=inject('back');
const selectedType = ref('PAYOUT');
let edit = 0;
let itemCode = '';
const errors = ref('');  
const success = ref('');
const errorId=ref('');
const errorsBis = {'name':''};
const pageLoad = ref(false);
const role=ref('');
const selectedUser = ref('');
const users = ref(new Array<User>());
const token =  localStorage.getItem('login_token');
role.value =  localStorage.getItem('role');
const userCode =  localStorage.getItem('code');
let codeOrganization: string|null = localStorage.getItem('organization_code');
const accounts =ref(new Array<Account>());

function handleUserChange(event) {
  selectedUser.value = event.target.value;
}

function handleTypeChange(event){
  selectedType.value = event.target.value;
}

const initField = () =>{
  formValues.name=  ''; 
  selectedType.value = 'PAYOUT'; 
  itemCode = '';
  edit=0;
  errorId.value=''
  errors.value=''
}

const save =()=>{
  if (edit==0){
    storeAccount()
  }else{
    updateUser()    
  }
  
}

  const formValues = reactive({
    name: "",  
    
  });

  
  const schema = yup.object({     
      name: yup.string().required('Le nom complet est requis'),        
  });



const leadPlaceholder = "Select Lead Date";

  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
let code:string|null='';
onMounted(() => {
  checkSessionValidate(1);
  flatpickr("#lead-calendar", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    defaultDate: "today",
  });

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });
  const route = useRoute()
  code = route.params.id;
  getUsers(code);
  getAccounts(codeOrganization);

  setTimeout(function () {
    pageLoad.value=true; 
         },1000)
  
});

const getUsers = (code) =>{

axios.get(`${backApi}/api/v1/secure/users/?organization=${code}`,{
      headers: {
          Authorization: `Bearer ${token}`
    }
  })
  .then((response) => {
      // users.value = response.data;
      users.value = response.data;  //.data.users          
      // console.log(users.value);
      
      
  }).catch((error) => {
        if( error.response){
            console.log(error.response.data); // => the response payload 
            if(error.response.status === 401){
              router.push('/')
              //window.location.href = '/';
            }   
            
        }
});
}
const getAccounts = (code) =>{

  axios.get(`${backApi}/api/v1/secure/accounts/?organization=${code}`,{
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        
        accounts.value = response.data;       
        // console.log(accounts.value);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
              }   
              
          }
  });
}


const storeAccount =()=>{
try {  selectedUser
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/accounts/new`,{
          name:formValues.name,
          type:selectedType.value,
          balance: 0,
          organization:code,
          user:(role.value=='ADMIN')?selectedUser.value:null
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){   
          showToast("Compte de type "+selectedType.value.toLowerCase()+" enregistré avec succès","green");   
          formValues.name='';
          selectedType.value='';
          // router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              }       
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}


}

const updateUser =()=>{
try {  
  schema.validateSync(formValues);
  axios.put(`${backApi}/api/v1/secure/users/${itemCode}/edit`,{
          name:formValues.name,
          // role:selectedRole.value,
          // status:selectedValidation.value,
          // email:formValues.email,
          // password:formValues.password,
          organization:code,
          code:itemCode,
          type:'API',
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){   
          showToast("Utilisaeur modifié avec succès","green");   
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              }    
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}


}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const changeStatus =(status,code)=>{
  axios.post(`${backApi}/api/v1/secure/accounts/status`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {  
        showToast("Statut du compte modifié avec succès","green");      
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              
              showToast(error.response.data.message,"red"); 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}
</script>