<template>
  <BreadCrumb PageTitle="" />
  <ExportContent />
</template>
<!--old page title= Export des données financière -->
<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ExportContent from "../../components/Financial/ExportContent.vue";


export default defineComponent({
  name: "ExportPage",
  components: {
    BreadCrumb,
    ExportContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>