<template>
   <!-- <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div> -->
  <!-- v-if="pageLoad" -->
  <div  class="row justify-content-center"  style="margin-top: -8%;">
    <div class="col-md-8 col-lg-6 col-xl-7 col-xxl-6 col-xxxl-4">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
            <h4 v-if="step3==0 && step2==0 " class="text-black fw-bold mb-0 text-center">
              Payez ici
            </h4>
            <div v-if="errors!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
            </div>
            <div v-if="success!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
            </div>
          <form  @submit.prevent="initTransaction">
            
            <div class="row" v-if="step1==1" >   
                <div class="form-group col-md-6">  
                  <!-- <div class="col-md-6">  -->
                      <label class="d-block text-black fw-semibold mb-5">
                        Numéro de téléphone 
                        </label>
                        <input
                        type="tel"
                        class="form-control shadow-none rounded-0 text-black field"
                        placeholder="0708204050"
                        v-model="phone"
                        id="phone"                
                      @keyup="handlePhone($event.target.value,$event.target)"
                        @focus="initOperator($event.target.value,$event.target)"
                        @blur="handlePhone($event.target.value,$event.target)"
                      />   
                </div> 
                <div class="form-group col-md-6"> 
                <!-- <div class="col-md-6">  -->
                      <label class="d-block text-black fw-semibold mb-5">Montant</label>               
                      <input
                        type="number"
                        class="form-control shadow-none rounded-0 text-black field"
                        placeholder="e.g. 5 000"
                        v-model="amount"   
                        @keyup="validate_phone_number($event.target.value,$event.target)"         
                        
                        :class="{ 'is-invalid': errorsBis['amount'] }"
                        v-if="amount==0"
                        required
                      />  
                      <input
                        type="number"
                        class="form-control shadow-none rounded-0 text-black field"
                        placeholder="e.g. 5 000"
                        v-model="amount"  
                        
                        @keyup="validate_phone_number($event.target.value,$event.target)"         
                        
                        
                        v-if="amount>0"
                        disabled                
                      />  
                </div>                 
              
            </div> 
            <!-- id="flexSwitchCheckDefault" -->
            
            <div class="form-group mt-10 mb-10 mb-sm-10 mb-md-25"  v-if="step1==1">
                          
              <div class="col-md-12">
                  <label class="d-block text-black fw-semibold mb-5">
                    Opérateurs
                  </label>
                  <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none selectfield" @change="handleOperatorChange" required>
                      <option value="">Selectionnez...</option>                 
                      <option v-for="operator in operators" :value="operator.mnoName" :key="operator.id"> {{ operator.mnoName }}</option>
                  </select>
              </div>              
            </div>

            <div   v-if="step1==1"
              class="text-center form-check form-check-primary mb-0 fs-md-15 fs-lg-16 mr-0 text-muted lh-1 mb-15 mb-md-20"
              
            > 
            <!-- padding-right: 10%; -->
            <!-- style="padding-left: 18%;"   -->
            <img :src="all" height="50%" width="50%"/>     
            </div>           
            <button   v-if="step1==1"  @click="next"
              class="default-btn transition fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100 border border-warning "              
              style="background-color: #fff;border-color:#EB9355;border-width:1px;border-style: solid;"
            >
              <img  v-if="selectedOperator==OTP_OPERATOR" :src="orange" width="25" height="25"/>
              <img  v-if="selectedOperator==MTN_OPERATOR" :src="mtn" width="25" height="25"/>
              <img  v-if="selectedOperator==MOOV_OPERATOR" :src="moov" width="25" height="25"/>
              <img  v-if="selectedOperator==WAVE_OPERATOR" :src="wave" width="25" height="25"/>
              <span style="color:#EB9355">Payer</span>
            </button>
            <!-- step 2 -->
            <div v-if="step2==1 && selectedOperator==OTP_OPERATOR" class="form-group mb-15 mb-sm-20 mb-md-25">
                <h2>Confirmation du paiement</h2>               
            </div>
            <div v-if="step2==1 && selectedOperator==OTP_OPERATOR" class="row line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
              Suivez les étapes suivantes pour obtenir le code d’autorisation.

              1. Pour générer un code temporaire, ouvrez l’appli Orange Money Afrique et cliquez sur Mon Compte, ou composez le #144*82# et lancez l’appel
              2. Tapez votre code secret et validez
              3. Veuillez copier et coller le code reçu par SMS dans le champ ci-dessous.
            </div>
            <div v-if="step2==1 && selectedOperator!==OTP_OPERATOR" class="form-group mb-15 mb-sm-20 mb-md-25">
                <h2>Vérification du paiement</h2>               
            </div>
            <div v-if="step2==1 && selectedOperator!==OTP_OPERATOR" class="row line-numbers bg-gray pt-1 pb-0 ps-25 pe-25 mb-0">
               <span v-if="selectedOperator==MTN_OPERATOR" >Veuillez saisir votre code secret MTN Money pour confirmer le paiement.</span>
               <span v-if="selectedOperator==MOOV_OPERATOR" >Veuillez saisir votre code secret FLOOZ pour confirmer le paiement.</span>
               <span v-if="selectedOperator==WAVE_OPERATOR" >Vous serez redirigé dans quelques instants vers la page affichant le qrcode à scanner pour finaliser votre opération.</span>
            </div>
            <div id="compte_a_rebours"  v-if="step2==1 && selectedOperator!==OTP_OPERATOR" class="form-group mb-15 mb-sm-20 mb-md-25">
                <p> {{(mins<0 && secs<0) ? "" : (                  
                mins+":"+((secs < 10 )? `0${secs}` : secs)               
                )}}
                </p>               
            </div>
            <div class="form-group mt-10  mb-10 mb-sm-10 mb-md-25"  v-if="step2==1 && selectedOperator==OTP_OPERATOR">
              <label class="d-block text-black fw-semibold mb-5">
                Saisir le code reçu par SMS
              </label>
              <input
                id="otp"
                type="text"
                class="form-control shadow-none rounded-0 text-black field"
                placeholder="**************"
                v-model="formValues.otp"
                @blur="validate('otp')"
                @keyup="validate_phone_number($event.target.value,$event.target,4)"
                :class="{ 'is-invalid': errorsBis['otp'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['otp']}}</span>
            </div>            
           
            <div class="row justify-content-center"   v-if="step2==1">
              <div class="col-md-6 col-lg-6">
                  <button   @click="goback"
                    class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100 border border-warning"              
                    style="background-color: #fff;border-color:#EB9355;border-width:1px;border-style: solid;"
                  >  
                                  
                  <i class="flaticon-chevron-1 mt-2"  style = "color:#EB9355;"></i>      
                  <span class="align-baseline" style="color:#EB9355">Retour</span>
                  </button>
              </div>
              <div class="col-md-6 col-lg-6">
                  <button 
                    class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
                    type="submit"
                    style="background-color: #EB9355;" :disabled="!btnLoad"
                  >
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Payer
                  </button>
              </div>
            </div>
            <!-- step 3 -->
            <div v-if="step3==1 && status=='SUCCEEDED'" class="form-group mb-15 mb-sm-20 mb-md-25">
                <h2 class="text-center"><i class="flaticon-secure-shield"  style = "color:green;"></i></h2> 
                <h3>Votre paiement a été approuvé</h3>               
            </div>
            <div v-if="step3==1 && status=='FAILED'" class="form-group mb-15 mb-sm-20 mb-md-25">
                <h2 class="text-center"><i class="flaticon-cancel"  style = "color:red;"></i></h2>     
                <h2>Votre paiement a échoué</h2>               
            </div>
            <div v-if="step3==1 && status=='PROCESSING'" class="form-group mb-15 mb-sm-20 mb-md-25">
                <h2 class="text-center"><i class="flaticon-compare"  style = "color:blue;"></i></h2> 
                <h2>Votre paiement est en cours</h2>               
            </div>
            <div v-if="step3==1" class="row line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0">
              <p><span class="text-black fw-bold">Opérateur : </span><span>{{ selectedOperator }}</span></p>
              <p><span class="text-black fw-bold">ID Transaction : </span><span>{{ checkoutId }}</span></p>
              <p><span class="text-black fw-bold">Date Paiement : </span><span>{{ formatDateFr(new Date()) }}</span></p>
              
            </div>    
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup  lang="ts">
import router from '@/router';
import { useRoute} from 'vue-router'
import axios from 'axios';
import { ref, reactive,inject,onMounted } from 'vue';
import * as yup from 'yup';
import data from '../../../../env.json'
import "intl-tel-input/build/css/intlTelInput.css";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import intlTelInput from 'intl-tel-input';
import ci from "intl-tel-input/i18n/ci"; 
import { Country,Fee,Operator } from "@/utils/Model";

import mtn from "../../../assets/images/operator/mtn1.png"
import wave from "../../../assets/images/operator/wave.png"
import orange from "../../../assets/images/operator/om1.png"
import moov from "../../../assets/images/operator/moov1.png"
import all from "../../../assets/images/operator/all_operator.png"

import {validate_phone_number,formatDateFr} from "../../../utils/function";
const backApi=inject('back');
const error = ref('');
const errors = ref('');
const errorId = ref('');
const success = ref('');
const errorsBis = {'otp':"",'amount':0};//'name':'',
const phone = ref("");
const operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();

let token: string|null = localStorage.getItem('login_token');
const selectedOperator = ref('');
let contrycode ='';
let contryIndicatif =''
const step1 = ref(1);
const step2 = ref(0);
const step3 = ref(0);
const OTP_OPERATOR = ref(process.env.VUE_APP_OTP_OPERATOR);
const MTN_OPERATOR = ref(process.env.VUE_APP_MTN);
const MOOV_OPERATOR = ref(process.env.VUE_APP_MOOV);
const WAVE_OPERATOR = ref(process.env.VUE_APP_WAVE);
let checkoutId = "";
let publicToken:string|null='';
const amount = ref(0);
const waveradio = ref(0);
const status = ref(null);
let countRetry=0;
let verify_transact;
let sampleInterval;
const btnLoad = ref(true);
const pageLoad = ref(false);
let startingMinutes = ref(5);
let startingSeconds = ref(0);
const mins = ref(startingMinutes);
const secs = ref(startingSeconds);
let paymentUrl;

let  iti ;
const schema = yup.object({
    otp: yup.string(),//.min(4, 'Le code doit faire au moin 4 chiffres'),
    amount: yup.number().required('Le montant est requis'),    
});



const formValues = reactive({ 
  // otp: "0000", 
  otp:"",
  amount: 0,
});

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          // alert(errorsBis[field])
        });
        errors.value=''
    }

    
    function handlePhone(myvalue,tag) {
      var contry =iti.getSelectedCountryData().iso2;
      let phone_length=0;
      switch (contry) {
        case 'ci':
          phone_length=process.env.VUE_APP_CI_PHONE_LENTGH;
          break;        
        case 'sn':
          phone_length=process.env.VUE_APP_SN_PHONE_LENTGH;
          break;
        default:
          alert("Sélectionnez un pays");
      }
      validate_phone_number(myvalue,tag,phone_length)

    }

    function handleOperatorChange(event) {
      // alert(event.target.value)
      selectedOperator.value = event.target.value;
    }

    function contryChange(countryObject){
        //  alert(countryObject.dialCode)
         contrycode =countryObject.iso2;
         contryIndicatif =countryObject.dialCode;
        // console.log(countryObject)
        var j = 0;
        var operatorArr = new Array<Operator>();
        while(j<operatorsTemp.length){    
            if(operatorsTemp[j].countryCode == countryObject.iso2){         
                operatorArr.push(operatorsTemp[j]);
            }      
            j++;
        }
        operators.value = operatorArr;
    }

    function initOperator(myValue,tag){        
         contrycode =iti.getSelectedCountryData().iso2;
    
        var j = 0;
        let operatorArr = new Array<Operator>();
        while(j<operatorsTemp.length){    
            if(operatorsTemp[j].countryCode == contrycode.toUpperCase()){         
                operatorArr.push(operatorsTemp[j]);
            }      
            j++;
        }
        // alert(operatorArr[0].mnoName);
        // alert(waveradio.value);
        operators.value = operatorArr;
    }

   
    const next = () => {
      let numero = phone.value;
      
      if(checkField()){
      // if(selectedOperator.value!=="" && amount.value>0 && (numero.length==process.env.VUE_APP_CI_PHONE_LENTGH||numero.length==process.env.VUE_APP_SN_PHONE_LENTGH)){      
              step1.value=0;
              step2.value=1;
              if(selectedOperator.value!==OTP_OPERATOR.value){
                  initTransaction();
                // if(selectedOperator.value==MTN_OPERATOR.value || selectedOperator.value==MOOV_OPERATOR.value){
                  sampleInterval = setInterval(() => {
                    if (secs.value > 0) {
                        secs.value=secs.value-1;     
                      }
                      if (secs.value === 0) {
                            
                        if (mins.value < 0) { 
                          checkPublicTransactionStatus();
                          clearInterval(verify_transact);        
                          clearInterval(sampleInterval);   
                          btnLoad.value=true;
                          step3.value=1;
                          step2.value=0;
                          step1.value=0;               
                        
                          
                        } else {
                            mins.value=mins.value-1;   
                            secs.value=59;            
                            if ((mins.value-1) === -1) {
                              checkPublicTransactionStatus();
                              clearInterval(verify_transact);
                              clearInterval(sampleInterval);
                              btnLoad.value=true;
                              step3.value=1;
                              step2.value=0;
                              step1.value=0;
                              
                              
                            }        
                          
                        }
                      }
                    }, 1000);
                    
                    return () => {
                      clearInterval(sampleInterval);
                      
                    };
                // }
              }
      }
      // else{
      //   errorId.value="Erreur survenue";            
      //   errors.value="Veuillez entrer des données valides.";
      // }
    }

    const goback = () => {
      step1.value=1;
      step2.value=0;
      step3.value=0;
      router.go(0)
    }
const checkField = () => {
      let result = true;
      if(selectedOperator.value==""){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez sélectionner un opérateur.";
        result =  false;
      }
      if(amount.value<=0){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un montant valide.";
        result =  false;
      }
      if(phone.value==""||(phone.value.length<process.env.VUE_APP_CI_PHONE_LENTGH && contrycode=="ci")||(phone.value.length<process.env.VUE_APP_SN_PHONE_LENTGH && contrycode=="sn")){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un numéro valide.";
        result =  false;
      }
      
     return result ;
}

const checkFieldForm = () => {
      let result = true;
      if(selectedOperator.value==""){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez sélectionner un opérateur.";
        result =  false;
      }
      if(amount.value<=0){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un montant valide.";
        result =  false;
      }
      if(phone.value==""||(phone.value.length<process.env.VUE_APP_CI_PHONE_LENTGH && contrycode=="ci")||(phone.value.length<process.env.VUE_APP_SN_PHONE_LENTGH && contrycode=="sn")){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un numéro valide.";
        result =  false;
      }
      if(formValues.otp=="" && OTP_OPERATOR.value==selectedOperator.value){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un code otp valide.";
        // alert(formValues.otp)
        result =  false;
      }
     return result ;
}

const initTransaction = async () => {
      
     
      if(checkFieldForm()){
        btnLoad.value=false;
          try {  
            
          
            schema.validateSync(formValues);
            
            axios.post(backApi+'/api/v1/public/transactions/init', {//http://127.0.0.1:8000
              operator: selectedOperator.value, 
              publicToken: publicToken,       
              amount: amount.value,
              mobile:phone.value,//"0708195112",//phone.value,
              operationType: parseInt(process.env.VUE_APP_PAYIN_OPTTYPE),
              countryCode: contrycode,         
              otp: formValues.otp,
              successUrl: "https://billing.api/success",
              errorUrl: "https://billing.api/error"
            }, {
            headers: {          
              'Content-Type': 'application/json',   
              // Authorization: `Bearer ${token}`        
            }
          }).then((response) => {        
            if(response.status == 201){
              checkoutId = response.data.checkoutId;
            
              if(selectedOperator.value==WAVE_OPERATOR.value || selectedOperator.value==OTP_OPERATOR.value || selectedOperator.value==MTN_OPERATOR.value || selectedOperator.value==MOOV_OPERATOR.value){
                verify_transact=setInterval(checkPublicTransactionStatus,10000);            
              }
              
              // success.value="Inscription effectuée avec succès nous vous contacterons pour la validation de votre compte"
                        
            }
          }).catch((error) => {
            btnLoad.value=true;
              if( error.response){  
                  errorId.value=error.response.data.errorId;            
                  errors.value=error.response.data.message;              
              }
          });
            

          } catch (error) {
            console.error(error);
            if( error.response){  
                  errorId.value=error.response.data.errorId;            
                  errors.value=error.response.data.message;              
              }
              btnLoad.value=true;
            //  errors.value="une erreur inattendue est apparue"//ValidationError:
          
          }
    }
    // else{
    //   alert('Manci')
    // } 
 
};


const showPublicTransaction = (token) =>{

axios.get(backApi+'/api/v1/public/transactions/'+token,{  //secure
  headers: {
    'Content-Type': 'application/json',    
  } 
})
.then((response) => {
  if(response.status == 200){
    amount.value = response.data.amount;  //.data.users   
    status.value = response.data.paymentStatus;       
    // console.log(response.data);
    
  }  
   
}).catch((error) => {
      if( error.response){
          // console.log(error.response.data); // => the response payload 
          if( error.response){  
              // errorId.value=error.response.data.errorId;            
              // errors.value=error.response.data.message;  
              // errorId.value=error.response.data.errorId;            
              errors.value=error.response.data;  
              router.push('/');           
          }  
          
      }
});
//  loader.value=true;
}

const checkPublicTransactionStatus = () =>{

axios.get(backApi+'/api/v1/public/transactions/'+publicToken,{  //secure
  headers: {
    'Content-Type': 'application/json',    
  } 
})
.then((response) => {
  if(response.status == 200){
     
    status.value = response.data.paymentStatus;  
    // alert(status.value) 
    countRetry+=1;
    if(selectedOperator.value==OTP_OPERATOR.value && (countRetry==3 || status.value=="SUCCEEDED" || status.value=="FAILED")){// || status.value=="FAILED"
      clearInterval(verify_transact);
      btnLoad.value=true;
      step3.value=1;
      step2.value=0;
      step1.value=0;
    }
    if((selectedOperator.value==MTN_OPERATOR.value || selectedOperator.value==MOOV_OPERATOR.value)&&( status.value=="SUCCEEDED" || status.value=="FAILED")){
            
            clearInterval(verify_transact);
            clearInterval(sampleInterval);
            btnLoad.value=true;
            step3.value=1;
            step2.value=0;
            step1.value=0;
            
    }
    if(selectedOperator.value==WAVE_OPERATOR.value){// || status.value=="FAILED"
      if( status.value=="SUCCEEDED" || status.value=="FAILED"){
        clearInterval(verify_transact);
        btnLoad.value=true;
        step3.value=1;
        step2.value=0;
        step1.value=0;
      }
      if(countRetry==1){
        paymentUrl = response.data.paymentUrl;
        window.open(paymentUrl,'_blank'); 
      }
      
    }
    console.log(response.data);
    
  }  
   
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if( error.response){  
              errorId.value=error.response.data.errorId;            
              errors.value=error.response.data.message;              
          }  
          
      }
});
//  loader.value=true;
}

const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
    'Content-Type': 'application/json',   
        // Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}

// <option  value="SN" >Sénégal</option>
// <option  value="CI" > Côte d'Ivoire</option>
onMounted(() => {

  const route = useRoute();
  publicToken = route.params.token;  
  checkoutId = "";
  getOperators();
  showPublicTransaction(publicToken)
  const inputc = document.querySelector("#phone");
  // setTimeout(function () {
  //  pageLoad.value=true; 
        //  },1000);
  
  iti = intlTelInput(inputc, {
    // any initialisation options go here
    onlyCountries: ["ci","sn"],
    // i18n: ci,
    initialCountry: "auto",
  // utilsScript: "/intl-tel-input/js/utils.js?1722010922246",
  geoIpLookup: function(callback) {
    fetch("https://ipapi.co/json")
      .then(function(res) { return res.json(); })
      .then(function(data) { callback(data.country_code); })
      .catch(function() { callback("ci"); });
  }
});
// document.getElementById('phone').style.display ="block"; 
// contrycode =iti.getSelectedCountryData().iso2;




});
</script>
<style scoped>

#compte_a_rebours{
  color:black;  
  font-size: 450%;
  font-weight: 'bold';   
}
.field{
  height: 30px;
}

.selectfield{
  height: 45px;
}
</style>