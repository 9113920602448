import router from '@/router';

export const current_permissions = (perm) => {
    const permissions = localStorage.getItem('role'); 
          let result=false;
          if(permissions!==null){
            const permissionArray = perm.split("-");
            for (let pas = 0; pas < permissionArray.length; pas++) {
                  if(permissions.trim()==permissionArray[pas]){
                    result=true;
                  }
                  
            }
          }
      //   return (permissions!==null)?perm.includes(permissions.trim()):false; 
        return result;
  };

  export const current_permissionsOld2 = (perm) => {
      const permissions = localStorage.getItem('role'); 
            // alert(typeof permissions);
            // alert(perm);
            
          return (permissions!==null)?perm.includes(permissions.trim()):false; 
          // return true;
    };
  

  export const current_permissions_old1 = (perm) => {
    const permissions = localStorage.getItem('role'); 
       
        return permissions==perm;   
  };

  export const can_access = (perm) => {       
        if(current_permissions(perm)){
          return true;
          }else{
            //window.location.href = '/dashboard';
            router.push('/dashboard')
        }    
  };

  export const current_permissions_old = (perm) => {
    const permissionsStr = localStorage.getItem('permissions'); 
        const permissionsTemp = JSON.parse(permissionsStr) ;
        const permissions = permissionsTemp.permissions;  
        //console.log(permissionsTemp.permissions);
        return permissions.includes(perm.trim());   
  };