<template>
  <div class="flex-grow-1"></div>
  <div class="toast-container position-fixed bottom-0 end-0 p-3" v-if="message!==null">
            <div
              id="liveToast"
              class="toast hide"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
                  <div class="toast-header">
                      <svg
                        class="rounded me-2"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                      >
                        <rect width="100%" height="100%" fill="#726eed"></rect>
                      </svg>
                      <strong class="me-auto text-black">Notification</strong>
                      <!-- <small class="text-muted">11 mins ago</small> -->
                      <button
                        type="button"
                        class="btn-close shadow-none"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                      ></button>
                  </div>
                  <div class="toast-body fs-md-15 fs-lg-16 text-paragraph lh-base">
                    {{ message }}
                  </div>
            </div>
          </div>
        
  <footer
    class="footer-area text-center text-md-start bg-white ps-15 pe-15 pt-20 pb-20 ps-md-40 pe-md-40 pt-md-25 pb-md-25"
    v-if="stateStoreInstance.show==true"
  >
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-6">
        <p class="text-body-secondary fw-medium mb-0">
          © <span class="fw-bold text-primary">Izypay</span>. Tous droits réservés à
          <a
            href="https://imoney.africa/"
            class="text-decoration-none fw-bold transition"
            target="_blank"
            >I-MONEY</a
          >
        </p>
      </div>
      <div class="col-md-6 col-lg-6 text-md-end">
        <ul class="footer-menu ps-0 mb-0 list-unstyled fw-medium">
          <!-- <li class="d-inline-block">
            <router-link
              to="/privacy-policy"
              class="text-decoration-none text-body-secondary transition"
            >
              Privacy Policy
            </router-link>
          </li> -->
          <!-- <li class="d-inline-block">
            <router-link
              to="/terms-conditions"
              class="text-decoration-none text-body-secondary transition"
            >
              Terms & Conditions
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import stateStore from "../../utils/store";
import {ref, onMounted,onBeforeMount,reactive,inject} from "vue";

const stateStoreInstance = stateStore;
// let message: string|null = localStorage.getItem('message');
let message=ref(localStorage.getItem('message'));
// alert(message.value);

// const showToast = () => {
//   const toastElement = document.getElementById("liveToast");
//   if (toastElement) {
//     const toastBootstrap = new window.bootstrap.Toast(toastElement);
//     toastBootstrap.show();
//   }
// };
// onMounted(() => {
//   stateStoreInstance.show = false;
//   if (typeof window.bootstrap === "undefined") {
//     const script = document.createElement("script");
//     script.src =
//       "https://cdn.jsdelivr.net/npm/bootstrap@5/dist/js/bootstrap.bundle.min.js";
//     script.onload = () => {
//       if(message.value!==null){
//         showToast();
//       }      
//     };
//     document.head.appendChild(script);
//   }
// });

// export default {
//   name: "MainFooter",
//   setup() {
//     const stateStoreInstance = stateStore;
//     return {
//       stateStoreInstance,
//     };
//   },
// };
</script>