<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
       
        
      </div>
      <div class="d-flex align-items-center">
        <button
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#companyModal"
          style="background-color: #EB9355;"
        >
          Créer un pays
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>


      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
              Code 
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
              Code pays
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Nom
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Indicatif
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                PHONE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Received
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Due
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Dévise
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                STATUS
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr   v-for="(country) in countries" :key="country.id">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ country.code }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ country.countryCode }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ country.name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ country.indicative }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ country.currency }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                +1-222-463-6429
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                $ 100.00 USD
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">$ 00.00 USD</td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                {{ formatDateFr(new Date(organization.created_at))}}
              </td> -->
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="country.status==='ACTIVE'">Actif</span>
                <span class="badge text-outline-danger" v-if="country.status==='INACTIVE'">Inactif</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <!-- <li>
                      <router-link
                        :to="`/transactions/${organization.code}`"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Transactions</router-link>
                    </li> -->
                    <!-- <li>
                      <router-link
                        :to="`/utilisateurs/${country.code}`"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Utilisateurs</router-link>
                    </li> -->
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="changeStatus(country.status,country.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="country.status==='ACTIVE'">Désactiver</span>
                        <span v-if="country.status==='INACTIVE'">Activer</span>
                      </a>
                    </li>
                    <!-- <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Edit</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li> -->
                  </ul>
                </div>
              </td>
            </tr>
                      
          </tbody>
        </table>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getCountries(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getCountries(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getCountries(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

   
  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="storeCountry">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Pays</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Code pays
                                </label>              
                                <div >                                     
                                        <input
                                          v-model="formValues.code"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="CI"
                                          @blur="validate('code')"
                                          @keyup="validate('code')"
                                          :class="{ 'is-invalid': errorsBis['code'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['code']}}</span> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Nom
                                </label>              
                                <div >                                     
                                        <input
                                          v-model="formValues.name"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="Cote d'Ivoire"
                                          @blur="validate('name')"
                                          @keyup="validate('name')"
                                          :class="{ 'is-invalid': errorsBis['name'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['name']}}</span> 
                                </div>
                            </div>
                          </div>
                          <div class="row">
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Indicatif 
                              </label>
                              <div >
                                      <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                      <input
                                        v-model="formValues.indicative"
                                        type="text"
                                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                        id="lead-calendar"
                                        placeholder="ex: 225"
                                        @blur="validate('indicative')"
                                        @keyup="validate('indicative')"
                                        @change="validate('indicative')"
                                        :class="{ 'is-invalid': errorsBis['indicative'] }"
                                      />
                                      <span class="invalid-feedback">{{ errorsBis['indicative']}}</span> 
                              </div>
                          </div>
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                               Dévise
                              </label>
                              <div >
                                                                   
                                    <input
                                    v-model="formValues.currency"
                                      type="text"
                                      class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                      id="lead-calendar"
                                      placeholder="ex: XOF"
                                      @blur="validate('currency')"
                                      @keyup="validate('currency')"
                                      :class="{ 'is-invalid': errorsBis['currency'] }"
                                    />
                                    <span class="invalid-feedback">{{ errorsBis['currency']}}</span> 
                              </div>
                          </div>
                        </div>
                       
                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import { checkSessionValidate } from "@/utils/function";
import { useRouter } from 'vue-router';
import * as yup from 'yup';

const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);



let token: string|null = localStorage.getItem('login_token');

declare interface Country {
  id : string,
  code: string,
  name: string,
  indicative: string,    
  currency: string,
  countryCode: string,
  created_at: string,
  status:string
  }
 
  const errors = ref('');  
  const success = ref('');
  const errorsBis = {'name':'','code':'','indicative':'','currency':''};
  const pageLoad = ref(false);

  const formValues = reactive({
    code: "",  
    indicative: "",  
    name: "",  
    currency: ""
  });

  const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  //const currencyRegExp = /^((\\+[A-Z]{3}[ \\-]*))?$/;
  const codeRegExp = /^([A-Z]{2})$/;
  const currencyRegExp = /^([A-Z]{3})$/;
  const indicativeRegExp = /^([0-9]{3})$/;
  const schema = yup.object({    
      code: yup.string().required('Le code pays est requis').matches(codeRegExp,"Veuillez saisir un code pays valide").test('len', "Le code pays doit faire 2 caractères", val => val.length === 2),  
      name: yup.string().required('Le nom du pays est requis'),   
      currency: yup.string().required('La dévise est requise').matches(currencyRegExp,"Veuillez saisir une dévise valide").test('len', "La dévise doit faire 3 caractères", val => val.length === 3),  
      indicative: yup.string().required('L\'indicatif est requis').matches(indicativeRegExp,"Veuillez saisir un indicatif valide").test('len', "L'indicatif doit faire 3 chiffres'", val => val.length === 3),     
  });


const countries =ref(new Array<Country>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
const getCountries = (arg=1) =>{
    // +arg
    axios.get(backApi+'/api/v1/secure/countries/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        countries.value = response.data;  //.data.countries            
        
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

        total.value=10;
        page.value=1;
        pagesLeft.value=0;
        currentPage.value=1;
        numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const changeStatus =(status,code)=>{
  //alert(code)
  axios.put(`${backApi}/api/v1/secure/countries/${code}/status`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const storeCountry =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/countries/new`,{
          countryCode:formValues.code,
          indicative:formValues.indicative,
          name:formValues.name,
          currency:formValues.currency
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}


onMounted(() => { 
  checkSessionValidate(1)
  getCountries();
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});
</script>